define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments-menu", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _runloop, _service, _rsvp, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-voting-comments-menu">
    {{#if this.expanded}}
      <PostVotingCommentsMenuComposer
        @id={{@id}}
        @onSave={{this.handleSave}}
        @onCancel={{this.closeComposer}}
      />
    {{else}}
      <DButton
        @display="link"
        @action={{if
          this.currentUser
          this.expandComposer
          (route-action "showLogin")
        }}
        @label="post_voting.post.post_voting_comment.add"
        class="post-voting-comment-add-link"
      />
    {{/if}}
  
    {{#if this.hasMoreComments}}
      {{#unless this.expanded}}
        <span class="post-voting-comments-menu-separator"></span>
      {{/unless}}
  
      <div class="post-voting-comments-menu-show-more">
        <DButton
          @display="link"
          @action={{this.fetchComments}}
          @translatedLabel={{i18n
            "post_voting.post.post_voting_comment.show"
            count=@moreCommentCount
          }}
          class="post-voting-comments-menu-show-more-link"
        />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "E3BmC2Ip",
    "block": "[[[10,0],[14,0,\"post-voting-comments-menu\"],[12],[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@id\",\"@onSave\",\"@onCancel\"],[[30,1],[30,0,[\"handleSave\"]],[30,0,[\"closeComposer\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],[[24,0,\"post-voting-comment-add-link\"]],[[\"@display\",\"@action\",\"@label\"],[\"link\",[52,[30,0,[\"currentUser\"]],[30,0,[\"expandComposer\"]],[28,[37,4],[\"showLogin\"],null]],\"post_voting.post.post_voting_comment.add\"]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"hasMoreComments\"]],[[[41,[51,[30,0,[\"expanded\"]]],[[[1,\"      \"],[10,1],[14,0,\"post-voting-comments-menu-separator\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"post-voting-comments-menu-show-more\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"post-voting-comments-menu-show-more-link\"]],[[\"@display\",\"@action\",\"@translatedLabel\"],[\"link\",[30,0,[\"fetchComments\"]],[28,[37,7],[\"post_voting.post.post_voting_comment.show\"],[[\"count\"],[[30,2]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@id\",\"@moreCommentCount\"],false,[\"div\",\"if\",\"post-voting-comments-menu-composer\",\"d-button\",\"route-action\",\"unless\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments-menu.hbs",
    "isStrictMode": false
  });
  class PostVotingCommentsMenu extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "expanded", [_tracking.tracked], function () {
      return false;
    }))();
    #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
    get hasMoreComments() {
      return this.args.moreCommentCount > 0;
    }
    handleSave(comment) {
      this.closeComposer();
      this.args.appendComments([comment]);
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleSave", [_object.action]))();
    expandComposer() {
      this.expanded = true;
      this.fetchComments().then(() => {
        (0, _runloop.schedule)("afterRender", () => {
          const textArea = document.querySelector(`#post_${this.args.postNumber} .post-voting-comment-composer .post-voting-comment-composer-textarea`);
          textArea.focus();
          textArea.select();
        });
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "expandComposer", [_object.action]))();
    closeComposer() {
      this.expanded = false;
    }
    static #_5 = (() => dt7948.n(this.prototype, "closeComposer", [_object.action]))();
    fetchComments() {
      if (!this.args.id) {
        return _rsvp.Promise.resolve();
      }
      const data = {
        post_id: this.args.id,
        last_comment_id: this.args.lastCommentId
      };
      return (0, _ajax.ajax)("/post_voting/comments", {
        type: "GET",
        data
      }).then(response => {
        if (response.comments.length > 0) {
          this.args.appendComments(response.comments);
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_6 = (() => dt7948.n(this.prototype, "fetchComments", [_object.action]))();
  }
  _exports.default = PostVotingCommentsMenu;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentsMenu);
});